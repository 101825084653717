import { render, staticRenderFns } from "./zvc-view.vue?vue&type=template&id=316ccf86&scoped=true"
import script from "./zvc-view.vue?vue&type=script&lang=js"
export * from "./zvc-view.vue?vue&type=script&lang=js"
import style0 from "./zvc-view.vue?vue&type=style&index=0&id=316ccf86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "316ccf86",
  null
  
)

export default component.exports